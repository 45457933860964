import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

@NgModule({
    imports: [
        MatButtonModule, MatCardModule, MatToolbarModule, MatFormFieldModule, MatInputModule,
        MatSelectModule, MatDialogModule, MatSidenavModule, MatIconModule, MatTooltipModule,
        MatListModule, MatCheckboxModule, MatProgressBarModule, MatTableModule, MatMenuModule,
        MatTabsModule, MatPaginatorModule, FlexLayoutModule, MatDatepickerModule, MatNativeDateModule,
        MatSnackBarModule, MatButtonToggleModule, MatRippleModule, MatSlideToggleModule, MatRadioModule,
        MatTreeModule, MatProgressSpinnerModule, MatChipsModule, MatAutocompleteModule
    ],
    exports: [
        MatButtonModule, MatCardModule, MatToolbarModule, MatFormFieldModule, MatInputModule,
        MatSelectModule, MatDialogModule, MatSidenavModule, MatIconModule, MatTooltipModule,
        MatListModule, MatCheckboxModule, MatProgressBarModule, MatTableModule, MatMenuModule,
        MatTabsModule, MatPaginatorModule, FlexLayoutModule, MatDatepickerModule, MatNativeDateModule,
        MatSnackBarModule, MatButtonToggleModule, MatRippleModule, MatSlideToggleModule, MatRadioModule,
        MatTreeModule, MatProgressSpinnerModule, MatChipsModule, MatAutocompleteModule
    ]
})
export class MaterialModule { }
