<h2 mat-dialog-title>{{this.dialogData.dialogTitle}}</h2>

<com-student-panel [student]="this.dialogData.student" [showAttorneyData]="true" [showAge]="true">
</com-student-panel>
<hr>
<div mat-dialog-content>
    <ul>
        <li *ngFor="let ele of this.dialogData.annotations">
           <strong>{{ele.createdAt}} - {{ele.annotationType.name}}</strong> : {{ele.text}}
           <br>
           <i>{{ele.user.fatherSurname}} {{ele.user.motherSurname}}, {{ele.user.names}}</i>
        </li>
    </ul>
</div>
<hr>
<div mat-dialog-actions>
    <button mat-raised-button color="primary" [mat-dialog-close]="" cdkFocusInitial>Cerrar</button>
</div>