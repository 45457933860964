<h3>Resumen de asistencia</h3>
<div fxLayoutAlign="space-evenly center" style="text-align: left;">
    <table border="0" width="95%">
        <tr>
            <td>&nbsp;</td>
            <td width="20%">
                <mat-form-field appearance="fill" fxFlexFill>
                    <mat-label>Mes</mat-label>
                    <mat-select [(ngModel)]="this.month" (selectionChange)="changeMonth($event)">
                        <mat-option value="">- Seleccione Mes -</mat-option>
                        <mat-option *ngFor="let month of this.months" [value]="month.number">
                            {{month.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </td>
            <td> del {{this.currentYear}}</td>
            <td width="30%"><com-course-selector (courseSelectEvent)="changeCourse($event)"></com-course-selector></td>
            <td>&nbsp;</td>
        </tr>
        <tr>
            <td colspan="5">
                <table border="0" width="100%" *ngIf="this.attendanceSummary">
                    <tr>
                        <td>Nº</td>
                        <td>Nombre</td>
                        <td align="center" *ngFor="let day of filterDays(this.attendanceSummary.days)">
                            {{convertWD(day.weekday)}}<br>{{day.number}}<br></td>
                    </tr>
                    <tr *ngFor="let student of this.attendanceSummary.students">
                        <td>{{student.numeroLista}}</td>
                        <td>{{student.paterno}} {{student.materno}}, {{student.nombres}}</td>

                        <td align="center" *ngFor="let day of filterDays(this.attendanceSummary.days)">

                            <mat-icon aria-hidden="false" 
                            (click)="this.showDetailCell(student, day)"
                            [style]="this.pointerForViewDetail(student, day)"
                                [color]="this.findCellColor(student,day)">{{findCellIcon(student, day)}}
                            </mat-icon>
                            <!--
                                                        [matTooltip]="this.findCellDesc(student,day)" 
                                matTooltipShowDelay="10" matTooltipHideDelay="10" 

                        -->
                        </td>
                    </tr>
                    <tr *ngIf="this.sigeIntegrationEnable">
                        <td colspan="2">Estado Informe Mineduc</td>
                        <td align="center" *ngFor="let item of filterDaysForMineduc(this.mineducSummary)">
                            <!-- (click)="showDetailMineduc(item)" style="cursor: pointer;" -->
                            <mat-icon [color]="this.findCellColorMineduc(item)" (click)="showDetailMineduc(item)"
                            [matTooltip]="this.tooltipForMineducItem(item)" 
                                [style]="this.pointerDetailMineduc(item)">{{findCellIconMineduc(item)}}
                            </mat-icon>

                        </td>
                    </tr>

                </table>
            </td>
        </tr>
        <tr>
            <td colspan="5">&nbsp;</td>
        </tr>
        <tr *ngIf="this.attendanceSummary && this.sigeIntegrationEnable">
            <td colspan="5">
                <div fxLayoutAlign="space-evenly center">
                    <button mat-raised-button color="primary" (click)="doSendToMineduc($event)">Enviar a
                        Mineduc...</button>
                </div>
            </td>
            <!--
            <td fxLayoutAlign="space-evenly center" colspan="5"><button mat-raised-button color="primary">Enviar a Mineduc...</button></td>
            -->
        </tr>
    </table>
</div>