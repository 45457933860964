import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Annotation } from '../model/annotation';
import { AnnotationTemplate } from '../model/annotation-template';
import { AnnotationType } from '../model/annotation-type';
import { AuthorService } from './author.service';

@Injectable({
    providedIn: 'root'
})

export class AnnotationService {
    private baseUrl: string = environment.apiUrl;
    private serviceUrl: string = '/api/lichan/annotation/1.0';
    private listTemplatesUrl: string = '/list-templates';
    private listTypesUrl: string = '/list-types';
    private appendUrl: string = '/append';
    private listAnnotationsUrl = '/list-annotations';


    constructor(private http: HttpClient,
        private authorService: AuthorService) {
    }

    public listAllTemplates(): Observable<AnnotationTemplate[]> {
        const url = this.baseUrl + this.serviceUrl + this.listTemplatesUrl;
        return this.http.get<AnnotationTemplate[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public append(annotation: Annotation): Observable<Annotation> {
        const url = this.baseUrl + this.serviceUrl + this.appendUrl;
        return this.http.post<Annotation>(url, annotation, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listAnnotations(studentId: string): Observable<Annotation[]> {
        const url = this.baseUrl + this.serviceUrl + this.listAnnotationsUrl + `/${studentId}`;
        return this.http.get<Annotation[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

    public listAllTypes(): Observable<AnnotationType[]> {
        const url = this.baseUrl + this.serviceUrl + this.listTypesUrl;
        return this.http.get<AnnotationType[]>(url, { headers: this.authorService.headerWithAuthorization(null) });
    }

}