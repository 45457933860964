<mat-card *ngIf="alumno" class="mat-elevation-z5 center" style="width: 80%;">
    <div>
        <div style="display: inline-block; vertical-align: text-bottom; width: 70%;">
            <h3 class="left">Alumno</h3>

            <h1 class="left">{{alumno.paterno}} {{alumno.materno}}, {{alumno.nombres}}
                <button mat-icon-button color="primary" *ngIf="this.canUpdateNameOrRut"
                    matTooltip="Actualizar nombres o apellidos" (click)="openChangeNameDialog()">
                    <mat-icon>edit</mat-icon>
                </button>

                <button mat-icon-button color="primary" *ngIf="showUnenrollStudentButton && alumno.estado != 'RET'"
                    matTooltip="Cancelación de alumno (Retiro)" (click)="openUnenrollDialog()">
                    <mat-icon>person_off</mat-icon>
                </button>
                <div style="color: red;" *ngIf="alumno.estado == 'RET'"><b>Retirado</b></div>
            </h1>

            <h4 class="left">Curso: {{alumno.course}}, Año: {{alumno.agnoActual}}

                <button mat-icon-button color="primary" *ngIf="showChangeCourseButton && alumno.estado != 'RET'"
                    matTooltip="Cambiar de curso" (click)="openChangeCourseDialog()">
                    <mat-icon>edit</mat-icon>
                </button>

            </h4>
        </div>
        <div style="display: inline-block; width: 30%;" class="center">
            <mat-card class="mat-elevation-z2">
                <img width="150px" src="{{photosUrlService}}/{{alumno.id}}" alt="Alumno">
                <p>Rut: {{alumno.rut}}-{{alumno.dv}}

                    <button mat-icon-button color="primary" *ngIf="this.canUpdateNameOrRut"
                        matTooltip="Cambiar número de Rut" (click)="openChangeRutDialog()">
                        <mat-icon>edit</mat-icon>
                    </button>

                </p>
            </mat-card>
        </div>
    </div>

    <mat-tab-group mat-align-tabs="start" selectedIndex="1">
        <mat-tab label="Alumno">
            <div class="twoFields center">
                <mat-form-field style="width: 45%;">
                    <mat-label>Fecha Nacimiento</mat-label>

                    <input matInput [matDatepicker]="picker" [(ngModel)]="this.nacimiento">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                </mat-form-field>

                &nbsp;
                <mat-form-field style="width: 45%;">
                    <mat-label>Número Matricula</mat-label>
                    <input matInput [readonly]="true" value="{{alumno.numeroMatricula}}">
                </mat-form-field>
            </div>
            <div class="twoFields center">
                <mat-form-field style="width: 45%;">
                    <mat-label>Dirección</mat-label>
                    <input matInput type="text " [readonly]="!this.canEditPersonalData" [(ngModel)]="alumno.direccion">
                </mat-form-field>
                &nbsp;
                <mat-form-field style="width: 45%;">
                    <mat-label>Comuna</mat-label>
                    <input matInput type="text" *ngIf="!this.canEditPersonalData" [(ngModel)]="alumno.comunaNombre">

                    <mat-select [(ngModel)]="alumno.comunaId" *ngIf="this.canEditPersonalData">
                        <mat-option *ngFor="let township of townships" [value]="township.id">
                            {{township.nombre}}
                        </mat-option>
                    </mat-select>

                </mat-form-field>
            </div>

            <div class="twoFields center">
                <mat-form-field style="width: 45%;">
                    <mat-label>Prevision</mat-label>
                    <input matInput type="text" *ngIf="!this.canEditPersonalData" [(ngModel)]="alumno.prevision">

                    <mat-select [(ngModel)]="alumno.prevision" *ngIf="this.canEditPersonalData">
                        <mat-option value="FONASA">Fonasa</mat-option>
                        <mat-option value="ISAPRE">Isapre</mat-option>
                        <mat-option value="CAPRED">Capredena</mat-option>
                        <mat-option value="DIPREC">Dipreca</mat-option>
                    </mat-select>

                </mat-form-field>
                &nbsp;
                <mat-form-field style="width: 45%;">
                    <mat-label>Grupo Familiar</mat-label>
                    <input matInput type="text " [readonly]="!this.canEditPersonalData" value="{{alumno.familia}}">
                </mat-form-field>

            </div>
            <div class="twoFields center">
                <mat-form-field style="width: 22%;" *ngIf="!this.canEditPersonalData">
                    <mat-label>Toma Religión</mat-label>
                    <input matInput type="text" value="{{alumno.takeReligion?'Si':'No'}}">
                </mat-form-field>
                <mat-form-field style="width: 23%;" *ngIf="!this.canEditPersonalData">
                    <mat-label>Tiene Internet</mat-label>
                    <input matInput type="text" value="{{alumno.tieneInternet?'Si':'No'}}">
                </mat-form-field>

                <mat-slide-toggle *ngIf="this.canEditPersonalData" [(ngModel)]="alumno.takeReligion">Toma
                    Religión</mat-slide-toggle>

                <mat-slide-toggle *ngIf="this.canEditPersonalData" [(ngModel)]="alumno.tieneInternet">Tiene
                    Internet</mat-slide-toggle>
                &nbsp;
                <mat-form-field style="width: 45%;">
                    <mat-label>Computadores</mat-label>
                    <input matInput type="text " [readonly]="!this.canEditPersonalData" value="{{alumno.cuantosPc}}">
                </mat-form-field>

            </div>

            <div *ngIf="this.canEditPersonalData"> <button mat-raised-button color="primary" matTooltipShowDelay="250"
                    matTooltipHideDelay="100" matTooltip="Actualiza los datos de alumno" (click)="update()">
                    <mat-icon>save</mat-icon> Grabar datos alumno
                </button></div>

        </mat-tab>
        <!-- ------------------------ -->
        <mat-tab label="Contactos">
            <apoderados-list *ngIf="this.alumno.id" [studentId]="this.alumno.id"></apoderados-list>
        </mat-tab>

        <mat-tab label="Documentos" fxFlexAlign="center">
            <p class="align-center">
                <button mat-raised-button color="primary" (click)="downloadCertificate()" style="width: 350px;"
                    class="align-left" matTooltip="Descarga de documento">
                    <mat-icon>picture_as_pdf</mat-icon> Certificado de alumno regular
                </button>
            </p>
            <p *ngFor="let button of this.customButtons" class="align-center">
                <button mat-raised-button (click)="downloadDocument(button.index)" style="width: 350px;"
                    class="align-left">
                    <mat-icon>picture_as_pdf</mat-icon> {{button.label}}
                </button>
            </p>
        </mat-tab>

        <mat-tab label="Calificaciones" *ngIf="this.canSeeScores && this.scoresV2">
            <table border="1" fxFlexFill>
                <tr *ngFor="let row of this.scoresV2.scores">
                    <td *ngFor="let col of filterGT0(row); let i = index"
                        [ngStyle]="{'text-align': i > 0 ? 'center': 'left'}">
                        <div [ngClass]="{'italic': this.excludeSubject.includes(row[0])}">{{col}}</div>
                    </td>
                </tr>
                <tr>
                    <td style="text-align:left"><strong>{{this.scoresV2.averagesInfo.lastTitle}}</strong></td>
                    <td *ngFor="let col of filterPeriods(this.scoresV2.averagesInfo.averages, this.scoresV2.averagesInfo.period)">{{col}}</td>
                    <td [attr.colspan]="this.scoresV2.averagesInfo.spanCounter">&nbsp;</td>
                    <td> {{this.getCurrentAverage(this.scoresV2.averagesInfo.averages, this.scoresV2.averagesInfo.period)}} </td>
                    <td>&nbsp;</td>
                    <td><strong>{{this.scoresV2.averagesInfo.averages['Final']}}</strong></td>
                </tr>
            </table>
        </mat-tab>


        <mat-tab label="Licencias" *ngIf="this.canSeeLicenses">
            <licenses-list [studentId]="this.alumno.id" [year]="this.alumno.agnoActual"></licenses-list>
        </mat-tab>
        <mat-tab label="Accidentes" *ngIf="this.canSeeLicenses">
            <accidents-list [student]="this.alumno" [year]="this.alumno.agnoActual"></accidents-list>
        </mat-tab>

        <mat-tab label="Hoja de vida">
            <div style="text-align: left;" *ngIf="annotations">
                <h3>Directas del alumno</h3>

                <ul *ngIf="filter4student(annotations, true, false).length > 0; else without4student">
                    <li *ngFor="let annotation of filter4student(annotations, true, false)">
                        <div>
                            <p><b>{{annotation.createdAt}}.
                                    {{annotation.annotationType.name}}:</b>&nbsp;{{annotation.text}}<br>
                                <i>{{annotation.user.names+' '+annotation.user.fatherSurname + ' ' +
                                    annotation.user.motherSurname}}.</i>
                            </p>
                        </div>
                    </li>
                </ul>
                <ng-template #without4student>
                    <ul>
                        <p><i>Sin anotaciones registradas.</i></p>
                    </ul>
                </ng-template>

                <h3>Anotaciones del curso</h3>
                <ul *ngIf="filter4student(annotations, false, false).length > 0; else without4course">
                    <li *ngFor="let annotation of filter4student(annotations, false, false);">
                        <div>
                            <p><b>{{annotation.createdAt}}.
                                    {{annotation.annotationType.name}}:</b>&nbsp;{{annotation.text}}<br>
                                <i>{{annotation.user.names+' '+annotation.user.fatherSurname + ' ' +
                                    annotation.user.motherSurname}}.</i>
                            </p>
                        </div>
                    </li>
                </ul>
                <ng-template #without4course>
                    <ul>
                        <p><i>Curso sin anotaciones.</i></p>
                    </ul>
                </ng-template>
            </div>
        </mat-tab>

        <mat-tab label="Eventos">
            <div style="text-align: left;" *ngIf="annotations">
                <h3>Directas del alumno</h3>

                <ul *ngIf="filter4student(annotations, true, true).length > 0; else without4student">
                    <li *ngFor="let annotation of filter4student(annotations, true, true)">
                        <div>
                            <p><b>{{annotation.createdAt}}.
                                    {{annotation.annotationType.name}}:</b>&nbsp;{{annotation.text}}<br>
                                <i>{{annotation.user.names+' '+annotation.user.fatherSurname + ' ' +
                                    annotation.user.motherSurname}}.</i>
                            </p>
                        </div>
                    </li>
                </ul>
                <ng-template #without4student>
                    <ul>
                        <p><i>Sin eventos registradas.</i></p>
                    </ul>
                </ng-template>

                <h3>Eventos colectivos</h3>
                <ul *ngIf="filter4student(annotations, false, true).length > 0; else without4course">
                    <li *ngFor="let annotation of filter4student(annotations, false, true);">
                        <div>
                            <p><b>{{annotation.createdAt}}.
                                    {{annotation.annotationType.name}}:</b>&nbsp;{{annotation.text}}<br>
                                <i>{{annotation.user.names+' '+annotation.user.fatherSurname + ' ' +
                                    annotation.user.motherSurname}}.</i>
                            </p>
                        </div>
                    </li>
                </ul>
                <ng-template #without4course>
                    <ul>
                        <p><i>Curso sin eventos.</i></p>
                    </ul>
                </ng-template>
            </div>

        </mat-tab>

        <mat-tab label="Salud" *ngIf="this.canAdminHealth || this.canViewHealth">
            <health-list [student]="this.alumno" [canAdminHealth]="this.canAdminHealth"
                [canViewHealth]="this.canViewHealth"></health-list>
        </mat-tab>


        <br>
    </mat-tab-group>

</mat-card>