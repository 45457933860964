import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'src/app/api/permission.service';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {
  public showSignaturesTab: boolean = false;
  public showFailProjection: boolean = true;
  constructor(private permissionService: PermissionService) { }

  ngOnInit(): void {

    this.loadPermissions();


  }

  private async loadPermissions() {
    this.showSignaturesTab = await this.permissionService.hasPermissionAsync('MANAGEMENT.SIGNATURES');
    this.showFailProjection = await this.permissionService.hasPermissionAsync('MANAGEMENT.FAILURE_PROJECTION');
  }

  //  

}
