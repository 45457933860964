import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthorService } from "../api/author.service";
import { VerifyPasswordDialogComponent, VerifyPasswordParamDialog } from "../gui/calificaciones/verify-password-dialog/verify-password-dialog.component";
import { SecretKey } from "../model/secret-key";
import { DialogMessageParameters, MessageDialogComponent } from "./message-dialog/message-dialog.component";
import { UploadFileDialogComponent, UploadFileDialogDataParam } from "./upload-dialog/upload-file-dialog.component";

@Injectable({
    providedIn: 'root'
})

export class AlertUtility {

    constructor(private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private authorService: AuthorService
    ) {
    }

    public fastMessage(message: string): void {
        this.snackBar.open(message, 'OK', { duration: 5000 });
    }

    public dialogMessage(message: string, title?: string, dialogMessageType?: DialogMessageType): void {
        const dialogParameters = new DialogMessageParameters();
        dialogParameters.message = message;
        dialogParameters.showCancelButton = false;
        dialogParameters.title = title == undefined ? 'Mensaje' : title;
        dialogParameters.type = dialogMessageType == undefined ? DialogMessageType.MESSAGE : dialogMessageType;

        this.dialog.open(MessageDialogComponent, { data: dialogParameters });
    }

    public async confirmMessage(message: string, title?: string): Promise<boolean> {
        const dialogParameters = new DialogMessageParameters();
        dialogParameters.message = message;
        dialogParameters.showCancelButton = true;
        dialogParameters.title = title == undefined ? 'Confirmación' : title;

        const dialogRef = this.dialog.open(MessageDialogComponent, { data: dialogParameters });

        const response = await dialogRef.afterClosed().toPromise();

        return response != undefined && response.response == 'OK';
    }

    public async verifyUserAndPassword(message: string, showMessage?: boolean, requestComment?: boolean): Promise<VerifyUserAndPasswordResponse> {
        const verifyPasswordParamDialog: VerifyPasswordParamDialog = new VerifyPasswordParamDialog();
        const useSecretKey: boolean = this.authorService.user.secretKey != null && this.authorService.user.secretKey.trim().length > 0;
        let isValid: boolean = false;
        verifyPasswordParamDialog.username = this.authorService.user.username;
        verifyPasswordParamDialog.password = '';
        verifyPasswordParamDialog.message = message;
        verifyPasswordParamDialog.showMessage = showMessage == undefined ? true : showMessage;
        verifyPasswordParamDialog.requestComment = requestComment == undefined ? true : requestComment;

        if (!useSecretKey) {
            verifyPasswordParamDialog.passwordLabel = 'Clave secreta de usuario';
            verifyPasswordParamDialog.passwordType = 'password';
            verifyPasswordParamDialog.passwordLen = -1;
        } else {
            verifyPasswordParamDialog.passwordLabel = 'Número de 6 dígitos...';
            verifyPasswordParamDialog.passwordType = 'text';
            verifyPasswordParamDialog.passwordLen = 6;
        }

        const response: VerifyUserAndPasswordResponse = new VerifyUserAndPasswordResponse();
        response.isValid = false;

        const dialogRef = this.dialog.open(VerifyPasswordDialogComponent,
            { data: verifyPasswordParamDialog }
        );

        const result: VerifyPasswordParamDialog = await dialogRef.afterClosed().toPromise();
        if (result == undefined || result.password == '') return response;

        if (!useSecretKey) {
            const verifiedPassword = await this.authorService.verifyPassword(result.password).toPromise();
            isValid = verifiedPassword.isValid;
        } else {
            const secretKey: SecretKey = new SecretKey();
            secretKey.code = result.password;
            secretKey.secretKey = this.authorService.user.secretKey;
            const verifiedPassword = await this.authorService.verifyCode(secretKey).toPromise();
            isValid = verifiedPassword.isValid;
        }

        response.isValid = isValid;
        response.comment = result.comment;

        return response;

    }

    public async uploadFileDialog(title: string, content: string): Promise<UploadFileDialogDataParam> {
        const params: UploadFileDialogDataParam = new UploadFileDialogDataParam();
        params.content = content;
        params.title = title;

        const dialogRef = this.dialog.open(UploadFileDialogComponent, { data: params });

        const result: UploadFileDialogDataParam = await dialogRef.afterClosed().toPromise();

        return result;
    }


}

export class VerifyUserAndPasswordResponse {
    comment: string;
    isValid: boolean;
}

export enum DialogMessageType {
    ERROR, WARNING, MESSAGE
}