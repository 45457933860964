import { AnnotationType } from './annotation-type';
import { User } from './user';
export class Annotation {
    id: string;
    annotationType: AnnotationType;
    annotationTypeId: string;
    its4Student:boolean;

    createdAt: string;
    courseId: string;
    subjectId: string;
    studentId: string;
    text: string;
    user : User
}